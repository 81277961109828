@tailwind base;
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color:#686A6C#d4af37;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: #686A6C;
}

*::-webkit-scrollbar-thumb {
  background-color:#d4af37;
  border-radius: 25px;
}
@tailwind components;
@tailwind utilities;

body {
  background-color: #191919;
  margin: 0;
  padding:0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 100px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
